<template>
  <div class="app-container">
    <el-row>
      <div>批量新增</div>
      <el-divider />
    </el-row>
    <el-form ref="form" :model="supplierform" :rules="rules" label-width="75px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="Style" prop="styleId">
              <el-select v-model="supplierform.styleId" value-key="id" clearable filterable @change="styleSelectChange">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Color">
              <el-select v-model="supplierform.color" clearable filterable>
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Size">

              <el-select v-model="supplierform.size" clearable filterable>
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="Sku">
              <el-input v-model="supplierform.sku" placeholder="多个以换行符分隔" type="textarea" autosize resize="none" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Fnsku">
              <el-input v-model="supplierform.fnsku" placeholder="请输入" type="textarea" autosize resize="none" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Upc">
              <el-input v-model="supplierform.upc" placeholder="请输入" type="textarea" autosize resize="none" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">重置</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <el-button class="mr-3" type="primary" :disabled="isNull" @click="handleTransfer">一键调拨</el-button>
      </el-col>
    </el-row>

    <vxe-table
      ref="uploadTableDataRef"
      v-loading="TableLoading"
      border
      max-height="500px"
      :data="tableDatas"
      :edit-rules="editRules"
      :edit-config="{trigger: 'click', mode: 'cell'}"
      @checkbox-change="({records})=>{selectlist=records}"
      @checkbox-all="({records})=>{selectlist=records}"
    >
      <vxe-column type="checkbox" width="60" />
      <vxe-column type="seq" title="序号" width="60" />
      <vxe-column field="categoryName1" title="一级类别" />
      <vxe-column field="specificationModel" title="规格型号" />
      <vxe-column field="sku" title="SKU" />
      <vxe-column field="availableStock" title="可用库存" />
      <vxe-column field="OrderNum" title="调拨数量" :edit-render="{}" prop="OrderNum">
        <!-- 提示具体sku -->
        <template #edit="{ row }">
          <vxe-input v-model="row.OrderNum" type="integer" :min="row.availableStock === 0 ? 0 : 1" :max="row.availableStock" placeholder="输入数值" />
        </template>
        <template #default="{ row }">
          <span>{{ row.OrderNum }}</span>
        </template>
      </vxe-column>

    </vxe-table>

    <el-row class="grid-content bg-blue" type="flex" justify="center" style="margin-top: 20px;">
      <el-button type="primary" @click="goBack">返回</el-button>
      <el-button type="primary" :loading="Loading" :disabled="isNull" @click="onSubmit">确认</el-button>
    </el-row>
  </div>
</template>
<script>
// queryTerraceList, querySiteList,
import { getLimitedStyle, querySizeList, queryColorList, batchInsertView, batchInsertValidate } from '@/api/scm-api'

export default {
  data() {
    return {
      TableLoading: false,
      editRules: {
        OrderNum: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      },
      rules: {
        styleId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      },
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      supplierform: {
        styleId: ''
      },
      tableDatas: [],
      // vendorId: this.$route.query.vendorId
      isDisabled: false,
      isDisabledTemp: true,
      siteCode: '',
      platformName: '',
      vendorOptions: [],
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      sidvoList: [],
      Loading: false,
      alldatas: []
    }
  },
  computed: {
    isIframe() {
      return this.$route.path.includes('/iframe/')
    },
    queryParameter() {
      const { color, styleId, sku, size, fnsku, upc } = this.supplierform
      const colorList = color ? [color] : []
      const sizeList = size ? [size] : []
      const skuList = sku ? this.preText(sku).split(/[,]/) : []
      const outWarehouseCode = this.$route.query.outWarehouseCode
      const outStockChannelId = this.$route.query.outStockChannelId
      return Object.assign({}, { styleId, colorList, skuList, sizeList, fnsku, upc }, { outWarehouseCode, outStockChannelId })
    },
    isNull() {
      if (Array.isArray(this.tableDatas) && this.tableDatas.length > 0) {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    this._queryStyleList()
  },
  methods: {
    preText(pretext) {
      const arr = []
      const array = pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',').replace(/\s/g, '&nbsp;').split(',') || []
      array.map(item => {
        if (item) {
          arr.push(item)
        }
      })
      return arr.join(',')
    },
    // 一键调拨
    handleTransfer() {
      const newTableDatas = this.tableDatas.filter(item => item.availableStock > 0)
      this.$refs.uploadTableDataRef.setCheckboxRow(newTableDatas, true)
      this.tableDatas.map((item) => {
        if (item.availableStock > 0) {
          return Object.assign(item, { OrderNum: parseInt(item.availableStock) })
        } else {
          return item
        }
      })
    },
    // 获取style数据带用户权限
    async _queryStyleList() {
      if (this.$route.query.transferTypeDict === '1') {
        const { datas } = await getLimitedStyle({ permissionFlag: false })
        this.styleOptions = datas
      } else {
        const { datas } = await getLimitedStyle({ permissionFlag: true })
        this.styleOptions = datas
      }
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.id === value) || []
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // 点击查询获取信息
    queryClick() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          const params = {}
          Object.assign(params, this.queryParameter, { transferTypeDict: this.$route.query.transferTypeDict })
          this._getPageByBatchInsert(params)
        } else {
          this.$message.error('Style必填')
          return false
        }
      })
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.supplierform.styleId = ''
      this.supplierform.color = ''
      this.supplierform.size = ''
      this.supplierform.sku = ''
      // this.queryClick(1)重置不搜索
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _getPageByBatchInsert(params) {
      try {
        this.TableLoading = true
        const { datas } = await batchInsertView(params)
        this.tableDatas = datas.map((item) =>
          Object.assign(item, { OrderNum: '' })
        )
      } finally {
        this.TableLoading = false
      }
    },

    goBack() {
      if (this.$route.query.edit) {
        this.$router.push({
          name: this.isIframe ? 'IframeTransferOrderModify' : 'TransferOrderModify',
          query: { transferOrderCode: this.$route.query.transferOrderCode }
        })
      } else {
        this.$router.push({
          name: this.isIframe ? 'IframeTransferOrderAdded' : 'TransferOrderAdded'
        })
      }
    },
    // 批量新增确认保存
    async onSubmit() {
      const $table = this.$refs.uploadTableDataRef
      const selectRecords = $table.getCheckboxRecords()
      if (Array.isArray(selectRecords) && selectRecords.length <= 0) return this.$message.warning('请选择数据')
      const errMap = await $table.validate(selectRecords).catch(errMap => errMap)
      const errNum = selectRecords.filter(item => item.OrderNum == 0)
      const { transferTypeDict } = this.$route.query
      if (errMap || errNum.length > 0) return this.$message.warning('调拨数量必填且大于0')

      if (Array.isArray(this.$route.query.DetailTable) && this.$route.query.DetailTable.length > 0) {
        const isRepeated = this.$route.query.DetailTable.some(item => {
          return selectRecords.some(i => i.sku === item.sku)
        })
        const repeatedSkus = this.$route.query.DetailTable.filter(item => {
          return selectRecords.some(i => i.sku === item.sku)
        }).map(item => item.sku)
        if (isRepeated) return this.$message.warning(`所选中的sku:${repeatedSkus.join(';')}不得与已添加的数据重复`)
        const isMoreStyle = this.$route.query.styleIdList.some(item => { return selectRecords.some(i => i.styleId !== item) })
        const isMoreColor = this.$route.query.colorList.some(item => { return selectRecords.some(i => i.color !== item) })
        if (this.$route.query.transferTypeDict == 1) {
          if (isMoreStyle) return this.$message.error('单个调拨单仅能维护单个Style的明细')
          this.onSave(selectRecords, transferTypeDict)
        } else {
          if (['topshoes_US_SHEIN'].includes(this.$route.query.inWarehouseCode)) {
            if (isMoreColor || isMoreStyle) return this.$message.error('调入仓库为【topshoes美国SFS仓】时，单个调拨单仅能维护单个Style及Color')
            this.onSave(selectRecords, transferTypeDict)
          } else {
            // dsb0510改动点 调出仓库的仓库类型=自管仓
            if (isMoreStyle && this.$route.query.outWarehouseType === 'OWN') return this.$message.error('单个调拨单仅能维护单个Style的明细')
            this.onSave(selectRecords, transferTypeDict)
          }
        }
      } else {
        const oldStyle = selectRecords.some(r => selectRecords.some(r1 => r1.styleId !== r.styleId))
        const oldColor = selectRecords.some(r => selectRecords.some(r1 => r1.color !== r.color))
        if (this.$route.query.transferTypeDict == 1) {
          if (oldStyle) return this.$message.error('单个调拨单仅能维护单个Style的明细')
          this.onSave(selectRecords, transferTypeDict)
        } else {
          if (this.$route.query.inWarehouseCode === 'topshoes_US_SHEIN') {
            if (oldColor || oldStyle) return this.$message.error('调入仓库为【topshoes美国SFS仓】时，单个调拨单仅能维护单个Style及Color')
            this.onSave(selectRecords, transferTypeDict)
          } else {
            if (oldStyle) return this.$message.error('单个调拨单仅能维护单个Style的明细')
            this.onSave(selectRecords, transferTypeDict)
          }
        }
        // this.onSave(selectRecords)
      }
    },
    async onSave(datas, transferTypeDict) {
      // await batchInsertValidate(datas)
      const params = {}
      const inStockChannelId = this.$route.query?.inStockChannelId
      Object.assign(params, { transferTypeDict, viewList: datas, inStockChannelId })
      const { code, datas: datasArray } = await batchInsertValidate(params)
      if (code === 0) {
        this.$notify({
          title: '批量新增成功',
          type: 'success'
        })
        // 若原有数据，则合并
        const oldData = sessionStorage.getItem('batchInsertTransfer')
        const positionData = datas.map(v => {
          const { position, negativeCashFlag } = datasArray?.find(e => e.sku === v.sku)
          return { ...v, position, negativeCashFlag }
        })
        if (oldData) {
          const newData = JSON.parse(oldData).concat(positionData)
          sessionStorage.setItem('batchInsertTransfer', JSON.stringify(newData))
        } else {
          sessionStorage.setItem('batchInsertTransfer', JSON.stringify(positionData))
        }
        // 新增和修改有两个返回路径
        this.goBack()
      }
    }
  }
}
</script>

